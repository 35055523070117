import React, { useEffect } from 'react'
// import { graphql, Link as GatsbyLink } from 'gatsby'
// import { Box, Button, Typography, makeStyles } from '@material-ui/core'
// import ArrowButton from '../components/Button/ArrowButton'
// import { TitleH2Bold } from '../theme/typography'
// import colors from '../theme/colors'
// import CanvasRenderer from '../components/Other/CanvasRender'
// import errorBall from '../images/error/spinning-middle.svg'
// import errorSkeleton from '../images/error/static-body.svg'
// import anime from 'animejs/lib/anime.es.js'
// import CssBaseline from '@material-ui/core/CssBaseline'
// import { MuiThemeProvider } from '@material-ui/core/styles'
// import { StylesProvider } from '@material-ui/styles'
// import { ThemeProvider as StyledThemeProvider } from 'styled-components'
// import theme from '../theme/theme'
// import { HelmetDatoCms } from 'gatsby-source-datocms'

// styles
// const pageStyles = {
//   margin: 0,
//   color: colors.blue5,
//   textAlign: 'center',
//   fontFamily: 'Sora, sans-serif, serif'
// }

// const useStyles = makeStyles((theme) => ({
//   errorTitle: {
//     fontSize: 210,
//     fontWeight: 'bold',
//     color: '#dadada',
//     lineHeight: 1,
//     marginBottom: 40,
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 170,
//       marginTop: 40,
//       marginBottom: 70
//     }
//   },
//   errorAnimation: {
//     position: 'relative',
//     width: 'calc(100vw - 16px)',
//     height: 140,
//     marginTop: -110
//   },
//   errorAnimationBody: {
//     position: 'absolute',
//     display: 'inline-block',
//     left: 0,
//     top: 0
//   },
//   animationBall: {
//     position: 'absolute',
//     bottom: 4,
//     left: 2,
//     animation: `$roll 1s .5s linear infinite`
//   },
//   animationSkeleton: {
//     position: 'relative'
//   },
//   '@keyframes roll': {
//     from: {
//       transform: 'rotate(0)'
//     },
//     '100%': {
//       transform: 'rotate(360deg)'
//     }
//   },
//   buttonWrap: {
//     marginTop: theme.spacing(8),
//     [theme.breakpoints.down('sm')]: {
//       marginTop: theme.spacing(4),
//       marginBottom: theme.spacing(4)
//     }
//   },
//   buttonPrimary: {
//     minWidth: '8rem'
//   },
//   textLink: {
//     marginLeft: theme.spacing(4),
//     '& a': {
//       marginTop: 0,
//       '&:hover::after': {
//         opacity: 0.1
//       }
//     }
//   },
//   title: {
//     letterSpacing: '2px',
//     [theme.breakpoints.down('sm')]: {
//       fontSize: '2rem'
//     }
//   },
//   errorLineWrap: {
//     position: 'absolute',
//     bottom: 48,
//     left: 0,
//     width: '80vw',
//     height: 2,
//     backgroundColor: '#dadada'
//   },
//   canvasEl: {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100%',
//     minHeight: '100vh',
//     backgroundColor: '#f4f4f4',
//     zIndex: 0,
//     opacity: 0.8
//   },
//   mainBody: {
//     padding: '0 7px',
//     position: 'relative',
//     zIndex: 1,
//     overflowX: 'hidden'
//   }
// }))

const NotFoundPage = () =>
  /*{ data: { datoCmsSite, datoCmsHomePage, datoCmsNotFoundPage }}*/
  {
    //While old truphone.com still exists, redirect there.
    useEffect(() => {
      window.location.href = `https://web.truphone.com${window.location.pathname}`
    }, [])
    return <></>

    // const classes = useStyles()
    // const animEl = useRef()
    // const animLine = useRef()

    // useEffect(() => {
    //   if (CanvasRenderer) {
    //     new CanvasRenderer('dotCanvas')
    //   }
    //   anime({
    //     targets: animEl.current,
    //     easing: 'easeOutCirc',
    //     duration: 1000,
    //     translateX: [0, '75vw'],
    //     complete: () => {}
    //   })
    //   anime({
    //     targets: animLine.current,
    //     easing: 'easeOutCirc',
    //     duration: 1000,
    //     width: [0, '78vw'],
    //     complete: () => {}
    //   })
    // }, [])
    // return (
    //   <>
    //     <HelmetDatoCms seo={datoCmsHomePage.seoMetaTags} favicon={datoCmsSite.faviconMetaTags} />
    //     <main style={pageStyles}>
    //       <canvas className={classes.canvasEl} id="dotCanvas"></canvas>
    //       <div className={classes.mainBody}>
    //         <h1 className={classes.errorTitle}>404</h1>
    //         <div className={classes.errorAnimation}>
    //           <div className={classes.errorAnimationBody} ref={animEl}>
    //             <img className={classes.animationBall} src={errorBall} />
    //             <img className={classes.animationSkeleton} src={errorSkeleton} />
    //           </div>
    //           <div className={classes.errorLineWrap} ref={animLine}></div>
    //         </div>
    //         <TitleH2Bold gutterBottom className={classes.title}>
    //           {datoCmsNotFoundPage.title}
    //         </TitleH2Bold>
    //         <Typography variant="body1">{datoCmsNotFoundPage.description}</Typography>
    //         <Box display="inline-flex" className={classes.buttonWrap}>
    //           <Button
    //             variant="contained"
    //             color="primary"
    //             className={classes.buttonPrimary}
    //             href={datoCmsNotFoundPage.homeCtaLink}
    //           >
    //             {datoCmsNotFoundPage.homeCtaText}
    //           </Button>
    //           <Box className={classes.textLink}>
    //             <ArrowButton href={`mailto:${datoCmsNotFoundPage.contactCtaLink}`} variant="text">
    //               {datoCmsNotFoundPage.contactCtaText}
    //             </ArrowButton>
    //           </Box>
    //         </Box>
    //       </div>
    //     </main>
    //   </>
    // )
  }

export default NotFoundPage

// export const query = graphql`
//   query NotFoundPage {
//     datoCmsSite {
//       faviconMetaTags {
//         ...GatsbyDatoCmsFaviconMetaTags
//       }
//     }
//     datoCmsHomePage {
//       seoMetaTags {
//         ...GatsbyDatoCmsSeoMetaTags
//       }
//     }
//     datoCmsNotFoundPage {
//       title
//       description
//       homeCtaText
//       homeCtaLink
//       contactCtaText
//       contactCtaLink
//     }
//   }
// `
